<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()
// computed
const unit = computed(() => 'Stück')
const getFromSub1Config = computed(() => store.getters['system/getFromSub1Config'])
console.log(getFromSub1Config)
const getSelection = computed(() => store.getters['system/getSelectionSubOne'])

// methods
function onChangeOption (e) {
  store.dispatch('system/setSubOneOption', {
    id: e.target.value,
    attr: e.target.name,
    checked: e.target.checked
  })
}
function onChangeDimension (e) {
  store.dispatch('system/setDimension', {
    config: e.target.id,
    type: 'width',
    value: e.target.value
  })
}
function sub (e) {
  store.dispatch('system/setQuantity', {
    value: e.target.value,
    action: '-',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
function add (e) {
  store.dispatch('system/setQuantity', {
    value: e.target.value,
    action: '+',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
</script>

<template>
  <div class="row">
<!--    <div class="col-md-12" v-for="(step, index) in getFromSub1Config" :key="index">-->
<!--      <p><strong>{{ step.name }}:</strong></p>-->
<!--      <div class="row">-->
<!--        <div v-for="(option, idx) in step.options" :key="idx" class="col-sm-3">-->
<!--          {{ option.type }}-->
<!--          <div class="form-check">-->
<!--            <input-->
<!--              type="radio"-->
<!--              :id="option.id"-->
<!--              :value="option.id"-->
<!--              :name="option.dname"-->
<!--              :data-step="step.id"-->
<!--              :checked="option.selected"-->
<!--              class="form-check-input"-->
<!--              :disabled="option.disabled"-->
<!--              @change="onChangeOption">-->
<!--            <label class="form-check-label" :for="option.id">-->
<!--              {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>-->
<!--            </label>-->
<!--          </div>-->

<!--          &lt;!&ndash;        <div v-else-if="option.type === 'check'" class="form-check" >&ndash;&gt;-->
<!--          &lt;!&ndash;          <input&ndash;&gt;-->
<!--          &lt;!&ndash;            type="checkbox"&ndash;&gt;-->
<!--          &lt;!&ndash;            :id="option.id"&ndash;&gt;-->
<!--          &lt;!&ndash;            :value="option.id"&ndash;&gt;-->
<!--          &lt;!&ndash;            :name="step.id"&ndash;&gt;-->
<!--          &lt;!&ndash;            :data-step="step.id"&ndash;&gt;-->
<!--          &lt;!&ndash;            :checked="option.selected"&ndash;&gt;-->
<!--          &lt;!&ndash;            class="form-check-input"&ndash;&gt;-->
<!--          &lt;!&ndash;            :disabled="option.disabled"&ndash;&gt;-->
<!--          &lt;!&ndash;            @change="onChangeOption">&ndash;&gt;-->
<!--          &lt;!&ndash;          <label class="form-check-label" :for="option.id">&ndash;&gt;-->
<!--          &lt;!&ndash;            {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>&ndash;&gt;-->
<!--          &lt;!&ndash;          </label>&ndash;&gt;-->
<!--          &lt;!&ndash;        </div>&ndash;&gt;-->

<!--          <div class="form-group d-flex" v-if="option.type === 'count'">-->
<!--            <input type="text" :value="option.value + ' ' + unit" class="form-control text-right" disabled>-->
<!--            <button class="btn btn-primary mx-1"  @click="sub" :id="option.id" :data-parent_id="step.id">-</button>-->
<!--            <button class="btn btn-primary" @click="add" :id="option.id" :data-parent_id="step.id">+</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="row">
    <div class="col-md-4">
      <div>
        <label class="">Breite</label>
        <div class="input-group mb-2">
          <select class="form-select" id="sub_1" @change="onChangeDimension">
            <option v-for="selectOption in getSelection" :value="selectOption.value" :key="selectOption.value" :selected="selectOption.selected">{{ selectOption.label }}</option>
          </select>
            <div class="input-group-text">Zentimeter</div>
        </div>
      </div>
    </div>
  </div>

</template>
